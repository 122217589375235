@import url('https://fonts.googleapis.com/css?family=Catamaran:300|Raleway:300,500');

// $primary: #a51616;
$primary: #ee4e61; /* MAIN COLOR */
$secondary: #007999; /* SECONDARY COLOR */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa; 
$border-radius: 0px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;

.flash {
	display:none;
}

nav {
	z-index: 1000;
}
h1,h2,h3 {
	font-family: 'Raleway', sans-serif;
	font-weight: 500;
}
p {
	font-family: 'Catamaran', sans-serif;
	font-size: 22px;
}
.navbar .navbar-nav {
    > li > a {
		text-align: center;
		font-family: 'Catamaran', sans-serif;

		@media (max-width: 767px) {
		    margin-top: 0;
		}

	    &:hover, &:focus {
	    	background: $primary;
	    	color: lighten($primary, 50%);
	    }
	}
}

.nav.navbar-nav {
  margin-top: 10px;
  @media (max-width: 767px) {
  	margin-top: 0;
  }
}

.navbar-toggle {
    margin: 30px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;
	
	@media (max-width: 388px) {
		margin-top: 14px;
	}
}


/** LOGIN FORM **/

@mixin btn1 {
	text-align: center;
	margin: 0 auto;
	border: 1px solid $primary;
	border-radius: $border-radius;
	background: $primary;
	color: $wht;
	border-radius: 10px;
	padding: 1em 3em;

	&:hover {
		background: lighten($primary,10%);
		color: lighten($wht, 10%);
		box-shadow: 0px 0px 3px lighten($blk,10%);
	}
}

@mixin btn2 {
	@include btn1;
	background: darken($wht, 5%);
	color: $primary;
	border: none;

	&:hover {
		background: $wht;
		color: $primary;
		border: none;
	}
}

.modal-dialog {
	max-width: 350px;
	width: 100%;
	text-align: center;
	margin: 6em auto;
	
	.close {display: none;}

	.modal-content {
		color: $primary;
		
		h2 {
			text-align:center;
		}
	}

	input {
		border: none;
		border-bottom: 1px solid darken($primary,20%);
		text-align: center;
	}
	
	button {
		background: transparent;
		color: $wht;
		display: block;
		border:none;
		padding: 1em 2em;
		margin: 0 auto;

		&:hover {
			background: transparent;
			color: #fff;
			box-shadow: none;
			text-shadow: 0px 0px 3px $blk;
			border:none;
		}
	}
	
	input[type="submit"] {
		@include btn1;
		display: block;
		width: 75%;	
	}

}

.modal-header, .modal-footer {
	background: $primary;
	color: $wht;
}

input#username {
	margin-bottom: 20px;
}

/** END LOGIN FORM **/

footer {
	padding: 50px 0px 20px;
	background: $primary;
	color: $footerLinks;

	a {
		color: $footerLinks;

		&:hover {
			color: lighten($footerLinks, 10%);
		}
	}
}


@mixin angle($pseudo, $flip: false, $angle: 2deg) {

  // Possible values for $pseudo are: before, after
  @if $pseudo == 'before' or $pseudo == 'after'{
    position: relative;
    z-index: 1;
    $selector: if($pseudo == '&:before', '&:after', '&:#{$pseudo}');

    #{$selector} {
      background: inherit;
      content: '';
      display: block;
      height: 100%;
      position: absolute;
      left: 0;
      right: 0;
      z-index: -1;
      -webkit-backface-visibility: hidden; // for Chrome Windows
    }

    @if $pseudo == 'before' {
      #{$selector} {
        top: 0;

        @if $flip {
          transform: skewY($angle * -1);
          transform-origin: 0 0;
          outline: 1px solid transparent;

        } @else {
          transform: skewY($angle);
          outline: 1px solid transparent;
          transform-origin: 100% 0;
        }
      }
    }

    @if $pseudo == 'after' {
      #{$selector} {
        bottom: 0;

        @if $flip {
          transform: skewY($angle);
          outline: 1px solid transparent;
          transform-origin: 0 100%;
        } @else {
          transform: skewY($angle * -1);
          outline: 1px solid transparent;
          transform-origin: 100%;
        }
      }
    }
  }
}

// edge positions
.ed-bottom {
  @include angle(after);
}

.ed-top {
  @include angle(before, true);
}

.ed-both {
   @include angle(after);
   @include angle(before, true);
}

.section1 {
  @include angle(before, true, 3deg);
  background: $wht;
  padding: 40px 0;
}
.section2 {
	@include angle(before, true, 3deg);
	background: $primary;
	padding: 40px 0;
	h1,p {
		color: white;
	}
}
.section3 {
	padding: 50px 0;
}
.banner {
  background: url(../img/banner.jpg) no-repeat;
  height: 800px;
  background-size: cover;
  background-attachment: fixed;
  padding: 300px 0;
  @media (max-width: 1024px) {
  	background-attachment: initial !important;
  }
  @media (max-width: 767px) {
  	background-position: 40% 50%;
  	padding: 100px 0;
  	height: auto;
  }
  h1,h2 {
    color: white;
    text-shadow: 2px 2px 2px black;
  }
  h1 {
  	font-size: 60px;
  }
  h2 {
  	font-size: 40px;
  }
}
hr {
	max-width: 150px;
}

.box {
  background: rgba(238,78,97,0.5);
}


.overlay {
	position: relative;
	overflow: hidden;
	padding: 0;
	margin: 0;
	img {
	width: 100%;
}
	h1, p {
		position: absolute;
	}
	h1 {
	top: 40%;
	color: white;
	opacity: 0;
	 transition: opacity 0.5s ease;;
	 z-index: 2;
	 width: 100%;
	 @media (max-width: 1024px) {
	 	opacity: 1;
	 }
}
&:hover {
	h1 {
		opacity: 1;
	}
	&:after {
		opacity: 1;
		background: rgba(0,0,0,0.6);
	}
}

}

.couple {
	background: url(../img/couple.jpg) no-repeat;
	background-size: cover;
	background-position: 50% 50%;
	padding: 200px 0px;
	@media (max-width: 991px) {
		margin-bottom: 50px;
	}
	@media (max-width: 767px) {
		height: 400px;
		padding: 0px;
	}
	&:after {
	position: absolute;
	content: "";
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	background-origin: content-box;
	opacity: 0;
	@media (max-width: 1024px) {
		opacity: 1;
		background: rgba(0,0,0,0.6);
}

}

}

.logo {
	max-width: 250px;
	@media (max-width: 767px) {
		max-width: 150px;
		margin-top: 10px;
	}

}

.pad {
	padding-top: 50px;
}

a.btn.btn-large {
	@include btn1;
}

.profile {
		display: block;
		margin: 0 auto;
}

@media (max-width: 500px) {
	.col-xs-6 {
		width: 100%;
	}
}